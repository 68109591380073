import React, { Component } from "react";
// import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Switch, Route } from "react-router-dom";
import Mainmenu from "./containers/mainmenu";
// import MainManagement from "./containers/User/mainmenu";
// import Student from "./containers/Student/student";
// import Parent from "./containers/Parent/parent";
// import StudentProfile from "./containers/Student/profile";
// import ParentProfile from "./containers/Parent/profile";

// import User from "./containers/User/user";
// import Role from "./containers/Role/role";

// import Books from "./containers/Library/books";
// import BookProfile from "./containers/Library/bookprofile";
// import History from "./containers/Library/History/history";
// import QRBook from "./containers/QR/qrbook";

//Oh
import Books from "./containers/Booking/books";
import BookProfile from "./containers/Booking/bookprofile";
import History from "./containers/History/history";
import Historylist from "./containers/History/historylist";

class Routes extends Component {
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Switch>
          {/* <Route exact path="/" component={Mainmenu} /> */}
          {/* <Route exact path="/" component={Parent} /> */}
          <Route exact path="/" component={History} />
          {/* <Route exact path="/dashboard" component={Mainmenu} /> */}

          {/*           <Route exact path="/library" component={Books} />
          <Route exact path="/library/bookprofile" component={BookProfile} />
          <Route exact path="/library/history" component={History} /> */}
          {/* <Route exact path="/qrbook" component={QRBook} />{" "} */}

          {/* oh */}
          <Route exact path="/booking" component={Books} />
          <Route exact path="/booking/bookprofile" component={BookProfile} />
          <Route exact path="/history/historylist" component={Historylist} />
        </Switch>
      </div>
    );
  }
}

export default Routes;
