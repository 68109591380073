import axios from "axios";
import * as config from "../../config";

//Todo
export function getBookHistory(qString) {
  return (
    axios
      // .get(config.urlAPI + "/report/bookhistory" + qString, config.headerCallAPI)
      .get(
        config.urlAPI + "/bookhistoryperpage" + qString,
        config.headerCallAPI
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      })
  );
}
//Todo
export function getDetailHistory(qString) {
  return axios
    .get(config.urlAPI + "/history/historylist" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
