import React, { Component } from "react";
import { Form, Grid, Dropdown } from "semantic-ui-react";
import Wrap from "./wrap";
import { DateInput, TimeInput } from "semantic-ui-calendar-react";
import moment from "moment";
import * as config from "./config";

class renderDateTimeField extends Component {
    state = {
        date: moment().format("DD-MM-YYYY"),
        time: moment().format("HH:mm"),
    };

    componentDidMount() {

        if (this.props.meta && this.props.meta.initial) {
            if (
                this.props.minDate &&
                moment(this.props.meta.initial, "DD-MM-YYYY HH:mm") <=
                moment(this.props.minDate, "DD-MM-YYYY HH:mm")
            ) {
                // console.log("less");
                this.setState({
                    date: moment(this.props.minDate, "DD-MM-YYYY HH:mm").format(
                        "DD-MM-YYYY"
                    ),
                    time: moment(this.props.minDate, "DD-MM-YYYY HH:mm").format("HH:mm"),
                });
            } else {
                // console.log("more");
                this.setState({
                    date: moment(this.props.meta.initial, "DD-MM-YYYY HH:mm").format(
                        "DD-MM-YYYY"
                    ),
                    time: moment(this.props.meta.initial, "DD-MM-YYYY HH:mm").format(
                        "HH:mm"
                    ),
                });
            }
        } else if (this.props.input && this.props.input.value) {
            // console.log("else if");
            if (
                this.props.minDate &&
                moment(this.props.meta.initial, "DD-MM-YYYY HH:mm") <=
                moment(this.props.minDate, "DD-MM-YYYY HH:mm")
            ) {
                // console.log("less");
                this.setState({
                    date: moment(this.props.minDate, "DD-MM-YYYY HH:mm").format(
                        "DD-MM-YYYY"
                    ),
                    time: moment(this.props.minDate, "DD-MM-YYYY HH:mm").format("HH:mm"),
                });
            } else {
                this.setState({
                    date: moment(this.props.input.value, "DD-MM-YYYY HH:mm").format(
                        "DD-MM-YYYY"
                    ),
                    time: moment(this.props.input.value, "DD-MM-YYYY HH:mm").format(
                        "HH:mm"
                    ),
                });
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.input && nextProps.input.value) {
            // if (nextProps.input && nextProps.input.value !== this.props.input.value) {
            if (moment(nextProps.input.value, "DD-MM-YYYY HH:mm").isValid()) {
                if (
                    moment(nextProps.input.value, "DD-MM-YYYY HH:mm") <
                    moment(nextProps.minDate, "DD-MM-YYYY HH:mm")
                ) {
                    this.setState({
                        date: moment(nextProps.minDate, "DD-MM-YYYY HH:mm").format(
                            "DD-MM-YYYY"
                        ),
                        time: moment(nextProps.minDate, "DD-MM-YYYY HH:mm").format("HH:mm"),
                    });
                } else {
                    this.setState({
                        date: moment(nextProps.input.value, "DD-MM-YYYY HH:mm").format(
                            "DD-MM-YYYY"
                        ),
                        time: moment(nextProps.input.value, "DD-MM-YYYY HH:mm").format(
                            "HH:mm"
                        ),
                    });
                }
            }
        }
    }

    onChange = (e, data) => {
        const state = this.state;
        if (data.name === "date") {
            this.setState({ date: data.value });
            this.props.input.onChange(data.value + " " + state.time);
        } else {
            this.setState({ [data.name]: data.value });
            this.props.input.onChange(state.date + " " + data.value);
        }
    };

    render() {
        const {
            input,
            label,
            placeholder,
            minDate,
            maxDate,
            width,
            required,
            readOnly,
            meta: { touched, error, warning },
        } = this.props;
        const state = this.state;

        return (
            <Wrap>
                <Form.Field width={width || 16}>
                    <Grid style={{ margin: 0 }}>
                        <Grid.Row columns="2" style={{ padding: 0 }}>
                            <Grid.Column
                                width="9"
                                style={{ paddingLeft: "0px", paddingRight: "0px" }}
                            >
                                <Form.Field>
                                    <DateInput
                                        name="date"
                                        placeholder={placeholder}
                                        // dateTimeFormat="DD/MM/YYYY"
                                        iconPosition="right"
                                        icon="calendar alternate outline"
                                        minDate={
                                            minDate &&
                                            moment(minDate, "DD-MM-YYYY HH:mm").format("DD-MM-YYYY")
                                        }
                                        initialDate={
                                            minDate
                                                ? moment(minDate, "DD-MM-YYYY HH:mm").format(
                                                    "DD-MM-YYYY"
                                                )
                                                : new Date()
                                        }
                                        value={state.date}
                                        // minDate={
                                        //   minDate &&
                                        //   moment(state.date, "DD-MM-YYYY").format("DD-MM-YYYY") >=
                                        //     moment(minDate, "DD-MM-YYYY HH:mm").format("DD-MM-YYYY")
                                        //     ? moment(minDate, "DD-MM-YYYY HH:mm").format(
                                        //         "DD-MM-YYYY"
                                        //       )
                                        //     : "25-05-2020"
                                        // }
                                        // initialDate={
                                        //   minDate
                                        //     ? moment(minDate, "DD-MM-YYYY HH:mm").format(
                                        //         "DD-MM-YYYY"
                                        //       )
                                        //     : moment().format("DD-MM-YYYY")
                                        // }
                                        // maxDate={maxDate && moment(maxDate, "DD-MM-YYYY HH:mm")}
                                        // initialDate={
                                        // moment(minDate, "DD-MM-YYYY HH:mm").format(
                                        //   "DD-MM-YYYY HH:mm"
                                        // ) === minDate
                                        //     ? moment(minDate, "DD-MM-YYYY HH:mm")._d
                                        //     : null
                                        // }
                                        // maxDate={maxDate && moment(maxDate, "DD-MM-YYYY HH:mm")}
                                        // value={state.date}
                                        marked={new Date()}
                                        markColor="green"
                                        onChange={this.onChange}
                                        hideMobileKeyboard
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column
                                width="7"
                                style={{ paddingLeft: "4px", paddingRight: "0px" }}
                            >
                                <Form.Field>
                                    <Dropdown
                                        placeholder="Select Friend"
                                        fluid
                                        selection
                                        scrolling
                                        className="time"
                                        value={state.time}
                                        onChange={this.onChange}
                                        options={config.timeSchedule}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <p style={{ color: "#9f3a38", textAlign: "left", fontSize: "90%" }}>
                        {touched &&
                            ((error && <span>{error}</span>) ||
                                (warning && <span>{warning}</span>))}
                    </p>
                </Form.Field>
            </Wrap>
        );
    }
}
export default renderDateTimeField;
