import React, { Component } from "react";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import LayoutPage from "./layout/layout";
import LoginPage from "./layout/login";

class App extends Component {
  render() {
    const login = sessionStorage.getItem("login");
    // if(window.location.pathname && window.location.pathname === '/resetpassword')
    // {
    //   return <ResetPass/>
    // }
    // return  <div className="App"> <LayoutPage userData={this.state.userData} /> </div>
    return <div className="App">{login ? <LayoutPage /> : <LoginPage />}</div>;
    // return (
    //   <div className="App">
    //     <LayoutPage />
    //   </div>
    // );
  }
}

export default App;
