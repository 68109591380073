import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import moment from "moment";
import * as config from "../../config";
import {
  Header,
  Image,
  Segment,
  Grid,
  Breadcrumb,
  Form,
  Loader,
  Dimmer,
  Tab,
  Table,
  Button,
  Icon,
  Modal,
} from "semantic-ui-react";
import { getBookProfile, updateBookProfile } from "./service";
import Wrap from "../../components/wrap";
import ModalBasic from "../../components/modal";
import Stock from "./stock";
import BookHistiry from "./bookhistory";
import FormBooks from "./formbook";
import FormImgBook from "./formimgbook.js";
import CustomConfirmmodal from "../../components/customconfirmmodal";
import { postBookingList } from "./service"; //Todo

import Bookconfirm from "./bookconfirm"; // add for bookconfirm

const BookProfile = (props) => {
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("kidzUserData"))
  ); //Todo
  const [loading, setLoading] = useState(true);
  const [bookProfiles] = useState(
    props && props.location.state && props.location.state.id
  );
  const [data, setData] = useState(null);
  const [errormsg, setErrormsg] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [openImgBook, setOpenImgBook] = useState(false);
  const [openBookconfirm, setOpenBookconfirm] = useState(false); // add for bookconfirm
  const [availableMode, setAvailableMode] = useState(false); //Todo: show/hide "BOOK NOW" button
  const [loadPopup, setLoadPopup] = useState(null);

  useEffect(() => {
    // if (!bookProfiles) props.history.push("/library/bookprofile");
    if (!bookProfiles) props.history.push("/booking/bookprofile");

    setLoading(true);
    if (bookProfiles) {
      getData();
    }
  }, [bookProfiles]);

  // console.log(props);

  const getData = () => {
    getBookProfile(props.location.state.id).then((res) => {
      if (res && res.status === 200) {
        setData(res.data);
      }

      if (res.data.borrow_status != "Available") setAvailableMode(true); //Todo: show/hide button
      setLoading(false);
    });
  };

  // const onEditProfile = (values) => {
  //   setShowPopup(true);
  //   setCurrentData(values);
  // };
  const onClose = () => {
    setShowPopup(false);
    setCurrentData(null);
  };
  // const updateData = (values) => {
  //   setLoading(true);
  //   setErrormsg(null);
  //   let data = values;
  //   updateBookProfile(values).then((res) => {
  //     if (res && res.status === 200) {
  //       getData();
  //       setShowPopup(false);
  //     }
  //     setCurrentData(null);
  //     setLoading(false);
  //   });
  // };

  // const onEditImgBook = (values) => {
  //   setOpenImgBook(true);
  //   setCurrentData(values);
  // };
  // const onCloseImgBook = () => {
  //   setOpenImgBook(false);
  //   setCurrentData(null);
  // };
  // const updateDataImgBook = (values) => {
  //   setLoading(true);
  //   let data = values;
  // };

  //Todo
  const onBookingAdd = (values) => {
    setLoadPopup(true);
    // setShowPopup(true);
    // setCurrentData(values);
    setErrormsg(null);
    // let data = values;
    values["student_id"] = userData.id;
    values["book_id"] = props.location.state.id;
    postBookingList(values).then((res) => {
      // console.log(res);
      if (res && res.status === 201) {
        // getData();
        // setShowPopup(false);
        setLoadPopup(false);
      } else if (res.data === 2) {
        setErrormsg("Overbook (Max. 2 books)");
      } else {
        setErrormsg(res.data.message);
        // setErrormsg("Over booking");
      }
      // setCurrentData(null);
      // setLoading(false);
    });
  };

  return (
    <Wrap>
      <Segment basic textAlign="left">
        <Header as="h2">Book Profile</Header>
        <Grid columns="2">
          <Grid.Column>
            <Breadcrumb>
              <Breadcrumb.Section
                onClick={() => props.history.push("/booking")}
                style={{ color: "grey" }}
              >
                Books
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section active>Book Profile</Breadcrumb.Section>
            </Breadcrumb>
          </Grid.Column>
          {/* <Grid.Column>
            <Button
              basic
              color="green"
              content="Edit Book"
              floated="right"
              style={{ marginBottom: "14px" }}
              onClick={() => onEditProfile(data)}
            />
            <Button
              basic
              color="green"
              content="Image"
              floated="right"
              style={{ marginBottom: "14px" }}
              onClick={() => onEditImgBook(data)}
            />
          </Grid.Column> */}
        </Grid>
        <Segment
          className="contentSegment"
          textAlign="left"
          style={{ minHeight: "70vh", padding: "20px" }}
        >
          {loading ? (
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          ) : (
            <Wrap>
              <Grid columns="2" doubling>
                <Grid.Column width="4">
                  <Image
                    verticalAlign="middle"
                    src={
                      data && data.imageURL
                        ? data.imageURL + "?time=" + new Date()
                        : "../storage/imgs/book.png"
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../storage/imgs/book.png";
                    }}
                  />
                </Grid.Column>
                <Grid.Column
                  columns="2"
                  width="12"
                  style={{ minHeight: "30vh" }}
                >
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as="h2" style={{ color: config.colorTheme2 }}>
                          <Header.Content>
                            {data.name_en}
                            <Header.Subheader
                              style={{ color: config.colorTheme2 }}
                            >
                              {data.name_th}
                            </Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns="2">
                      <Grid.Column columns="2">
                        <Grid columns="2">
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Type :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.type}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Category :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.category}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Item code :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.book_code}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Brand :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.brand}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Model :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.model}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            ISBN :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.isbn}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Author :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.author}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Number of pages :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.num_page} page
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Detail:{" "}
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.detail}
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                      <Grid.Column columns="2">
                        <Grid columns="2">
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Age range :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.age_range} year
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Borrowing Period(days) :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.borrowing_period} days
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Unit :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.unit}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Status Stock
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.borrow_status}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Status Item
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.status}
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid>
              <Grid columns="1">
                <Grid.Column>
                  <Button
                    basic
                    // color="blue"
                    color={availableMode ? "white" : "blue"}
                    content="BOOK NOW"
                    floated="right"
                    style={{ marginBottom: "14px" }}
                    disabled={availableMode ? true : false}
                    // onClick={() => setOpenBookconfirm(true)}
                    onClick={onBookingAdd} //Todo: add "complete booking"
                  />
                </Grid.Column>
              </Grid>
            </Wrap>
          )}
        </Segment>

        <Bookconfirm
          open={openBookconfirm}
          onClose={() => setOpenBookconfirm(false)}
          onClick={onBookingAdd} //Todo
        />

        {/* <ModalBasic open={showPopup} size="large" close={() => onClose()}>
          <FormBooks
            loading={loading}
            initialValues={currentData}
            onClose={() => onClose()}
            onSubmit={updateData}
          />
        </ModalBasic> */}

        {/* <ModalBasic
          open={openImgBook}
          size="big"
          close={() => onCloseImgBook()}
        >
          <FormImgBook
            loading={loading}
            initialValues={currentData}
            onClose={() => onCloseImgBook()}
            onSubmit={updateDataImgBook}
          />
        </ModalBasic> */}

        <Modal
          size={errormsg ? "tiny" : "mini"}
          open={loadPopup}
          closeIcon={errormsg ? true : false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={() => setLoadPopup(false)}
        >
          <Wrap>
            {errormsg ? (
              <Wrap>
                <Modal.Header>
                  <Icon name="warning sign" /> Warning
                </Modal.Header>
                <Modal.Content>{errormsg}</Modal.Content>
              </Wrap>
            ) : (
              <Modal.Content>
                <div style={{ textAlign: "center" }}>
                  <Icon loading name="circle notch" />
                  <br />
                  Add to booking list...
                </div>
              </Modal.Content>
            )}
          </Wrap>
        </Modal>
      </Segment>
    </Wrap>
  );
};

export default withRouter(BookProfile);
