import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as config from "../../config";
import {
  Button,
  Header,
  Icon,
  Modal,
  Segment,
  Form,
  Grid,
  Image,
  Breadcrumb,
} from "semantic-ui-react";
import Wrap from "../../components/wrap";
// import TableList from "../../components/table";
import TableList from "../../components/tablePerPage";
import ModalBasic from "../../components/modal";
import FormBooks from "./formbook";
import { DateInput, DatesRangeInput } from "semantic-ui-calendar-react";
import {
  getAllBooks,
  postBook,
  getBookingList,
  deleteBooking,
  confirmBooking,
} from "./service";

import "./Booking.css";
import "./Book.css";
import BookList from "./booking.js"; // add for booking

const Books = (props) => {
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("kidzUserData"))
  );
  //confirm popup ----------------------------
  const [open, setOpen] = useState(false);
  const showPopup = () => {
    setOpen(true);
  };
  const popUpClose = () => {
    setOpen(false);
    // window.location.reload();
    // window.close();
  };
  //-------------------------------------------

  const [msgConfirm1, setMsgConfirm1] = useState([]); //keep message
  const [msgConfirm2, setMsgConfirm2] = useState([]); //keep message
  const [bookingCount, setBookingCount] = useState(false);

  const [openbookinglist, setOpenbookinglist] = useState(false); // add for booking
  const [data, setData] = useState([]);
  const [dataBooking, setDataBooking] = useState([]);
  const [loading, setLoading] = useState(true);
  const [per_page, setPerPage] = useState(10);
  const [current_page, setCurrentPage] = useState(0);
  const [last_page, setLastPage] = useState(1);
  const [values, setValues] = useState({
    // type: "Book",
    type: "All",
    category: "All",
    statstock: "All",
    statitem: "All",
  });
  const [search, setSearch] = useState({ name: null });
  const [errormsg, setErrormsg] = useState(null);
  const [loadPopup, setLoadPopup] = useState(null);
  const [date, setDate] = useState(
    moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD")
  );
  // const [visit_date, setvisit_date] = useState(
  //   moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD")
  // );
  const [showPopupBooks, setShowPopupBooks] = useState(false);
  const [column, setColumn] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 50,
    },
    {
      Header: "Image",
      accessor: "id",
      sortable: false,
      Cell: (cellInfo) => (
        <Image
          size="mini"
          verticalAlign="middle"
          src={
            cellInfo.original.imageURL
              ? cellInfo.original.imageURL + "?time=" + new Date()
              : "../storage/imgs/book.png"
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "../storage/imgs/book.png";
          }}
        />
      ),
      style: { textAlign: "center" },
      width: 100,
    },
    {
      Header: "Type",
      accessor: "type",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Category",
      accessor: "category",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Code",
      accessor: "book_code",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Brand",
      accessor: "brand",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "ISBN",
      accessor: "isbn",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Name [EN]",
      accessor: "name_en",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Name [TH]",
      accessor: "name_th",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Age range",
      accessor: "age_range",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Author",
      accessor: "author",
      style: { textAlign: "center" },
      sortable: false,
    },
    // {
    //     Header: "Initail Stock",
    //     accessor: "id",
    //     style: { textAlign: "center" },
    //     sortable: false,
    //     Cell:  (cellInfo) => cellInfo.original.id
    // },

    {
      Header: "Status Stock",
      accessor: "borrow_status",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Status Item",
      accessor: "status",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Action",
      accessor: "id",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => (
        <Wrap>
          <Icon
            link
            bordered
            style={{
              color: config.colorTheme,
              boxShadow: "0 0 0 0.1em " + config.colorTheme + " inset",
            }}
            name="user"
            onClick={() =>
              props.history.push({
                pathname: "/booking/bookprofile",
                state: { id: cellInfo.original.id },
              })
            }
          />
        </Wrap>
      ),
    },
  ]);

  //Todo
  // set colum for bookinglist
  const [columnbookinglist, setColumnbookinglist] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 50,
    },
    {
      Header: "Image",
      accessor: "id",
      sortable: false,
      Cell: (cellInfo) => (
        <Image
          size="mini"
          verticalAlign="middle"
          src={
            cellInfo.original.imageURL
              ? cellInfo.original.imageURL + "?time=" + new Date()
              : "../storage/imgs/book.png"
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "../storage/imgs/book.png";
          }}
        />
      ),
      style: { textAlign: "center" },
      width: 100,
    },
    {
      Header: "Category",
      accessor: "category",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Code",
      accessor: "book_code",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Brand",
      accessor: "brand",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Model",
      accessor: "model",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "ISBN",
      accessor: "isbn",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Name [EN]",
      accessor: "name_en",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Name [TH]",
      accessor: "name_th",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Age range",
      accessor: "age_range",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Author",
      accessor: "author",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Return Due Date",
      accessor: "return_due_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    // {
    //   Header: "Quantity",
    //   accessor: "quantity",
    //   style: { textAlign: "center" },
    //   sortable: false,
    // },
    {
      Header: "Unit",
      accessor: "unit",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Action",
      accessor: "id",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => (
        <Wrap>
          <Icon
            link
            bordered
            style={{
              color: "red",
              boxShadow: "0 0 0 0.1em " + "red" + " inset",
            }}
            name="trash alternate outline" //bin icon
            onClick={() => onDeleteBooking(cellInfo.original.id)}
            // onClick={onBookingConfirm}
            // onClick={() =>
            //   props.history.push({
            //     pathname: "/booking/bookprofile",
            //     state: { id: cellInfo.original.id },
            //   })
            // }
          />
        </Wrap>
      ),
    },
  ]);

  const timeoutRef = useRef(null);

  useEffect(() => {
    if (!loading) {
      getData();
    }
  }, [per_page, current_page, values]);

  useEffect(() => {
    if (search) {
      setLoading(true);
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        getData();
      }, 2000);
    }
  }, [search]);

  const getData = () => {
    setLoading(true);

    let page = current_page + 1;
    let qString = "?_perpage=" + per_page + "&page=" + page;

    // let qString = "?";
    if (values.type && values.type != "All")
      qString = qString + "&type=" + values.type;
    if (values.category && values.category != "All")
      qString = qString + "&category=" + values.category;
    if (values.statstock && values.statstock !== "All")
      qString = qString + "&status_stock=" + values.statstock;
    if (values.statitem && values.statitem != "All")
      qString = qString + "&status_item=" + values.statitem;
    if (search) {
      if (search.name) qString = qString + "&name=" + search.name;
    }

    //get all books list
    getAllBooks(qString).then((res) => {
      // console.log(res); //Test
      if (res && res.status === 200) {
        setColumn(column);
        setData(res.data.data);
        setPerPage(per_page);
        setCurrentPage(res.data.current_page - 1);
        setLastPage(res.data.last_page);
      }
      // setLoading(false);
    });

    loadBookingList();

    // setLoading(false);
  };

  //Todo
  const loadBookingList = () => {
    //get booking list data
    let qbooking = "?";
    if (userData.id) qbooking = qbooking + "&student_id=" + userData.id; //Current user login
    getBookingList(qbooking).then((res) => {
      // console.log(res); //Test

      if (res && res.status === 200) {
        setColumnbookinglist(columnbookinglist);
        setDataBooking(res.data);
        setBookingCount(res.data.length);
      }
      setLoading(false);
    });
  };

  //Todo
  const onDeleteBooking = (bookid) => {
    setLoadPopup(true);
    setErrormsg(null);
    values["book_id"] = bookid;
    // console.log(values); //Test
    deleteBooking(values).then((res) => {
      // console.log(res); //Test
      if (res.data.affectedRows === 1 && res.status === 200) {
        // setShowPopup(false);
        setLoadPopup(false);
        loadBookingList();
      } else if (res.data.affectedRows == 0 && res.status === 200) {
        setErrormsg("Already confirm booking. Cannot delete.");
      } else {
        setErrormsg(res.data.message);
        // setErrormsg("Delete failed.");
      }
      // setCurrentData(null);
      // setLoading(false);
      // setLoadPopup(false);
    });
  };

  //Todo
  const onBookingConfirm = (values) => {
    setLoadPopup(true);
    setErrormsg(null);
    values["student_id"] = userData.id;

    confirmBooking(values).then((res) => {
      // console.log(res); //Test
      if (res && res.status === 201) {
        // setShowPopup(false);
        setLoadPopup(false);
        //confirm text
        // let txt;
        // txt =
        //   "Successful Booking  " +
        //   res.data.BookingID.booking_id +
        //   "  \n" +
        //   "Please pick up the book within " +
        //   res.data.pickupdue;
        // setMsgConfirm1("Successful Booking  " + res.data.BookingID.booking_id);
        // setMsgConfirm2("Please pick up the book within " + res.data.pickupdue);
        setMsgConfirm1(res.data.BookingID.booking_id);
        setMsgConfirm2(res.data.pickupdue);
        // console.log(txt); //Test
        showPopup(); //Successful Booking
      } else if (res.data.BookingID == null && res.status === 200) {
        setErrormsg("Already confirm booking.");
      } else {
        setErrormsg(res.data.message);
        // setErrormsg("Over booking");
      }
      // setCurrentData(null);
      // setLoading(false);
      getData();
      setOpenbookinglist(false);
    });
  };

  // const insertBook = (values) => {
  //   values["status"] = "Active";
  //   postBook(values).then((res) => {
  //     if (res && res.status === 201) {
  //       setShowPopupBooks(false);
  //     } else {
  //       setErrormsg(res.data.message);
  //     }
  //   });
  // };

  //Type Filter
  const handleSelectChangeType = (e, data) => {
    const { name, value } = data;
    setValues({ ...values, [name]: value });
  };
  //Category Filter
  const handleSelectChangeCategory = (e, data) => {
    const { name, value } = data;
    setValues({ ...values, [name]: value });
  };
  //Status stock Filter
  const handleSelectChangeStatStock = (e, data) => {
    const { name, value } = data;
    setValues({ ...values, [name]: value });
  };
  //Status item Filter
  const handleSelectChange = (e, data) => {
    const { name, value } = data;
    setValues({ ...values, [name]: value });
  };

  // const handleChangeDate = (event, { name, value }) => {
  //   setDate(value);
  // };

  //Search book
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...values, [name]: value });
  };

  return (
    <Segment basic textAlign="left">
      <Header as="h2">Books</Header>

      <Segment
        className="contentSegment"
        textAlign="left"
        style={{ minHeight: "50vh", padding: "20px" }}
      >
        <Form>
          <Form.Group>
            <Form.Input
              width="4"
              icon="search"
              size="small"
              placeholder="Search"
              name="name"
              onChange={handleInputChange}
            />

            <Form.Dropdown
              width="3"
              icon={null}
              fluid
              floating
              name="type"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Type : <b>{values.type}</b>
                </Button>
              }
              // options={config.options_bookType}
              options={[
                { key: "all", text: "All", value: "All" },
                ...config.options_bookType,
              ]}
              defaultValue={values.type}
              selectOnBlur={false}
              onChange={handleSelectChangeType}
            />

            <Form.Dropdown
              width="4"
              scrolling={true}
              icon={null}
              fluid
              floating
              name="category"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Category : <b>{values.category}</b>
                </Button>
              }
              options={[
                { key: "all", text: "All", value: "All" },
                ...config.options_bookCategory,
              ]}
              defaultValue={values.category}
              selectOnBlur={false}
              onChange={handleSelectChangeCategory}
            />

            <Form.Dropdown
              width="4"
              icon={null}
              fluid
              floating
              name="statstock"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Status Stock : <b>{values.statstock}</b>
                </Button>
              }
              // options={config.options_status_stock}
              options={[
                { key: "all", text: "All", value: "All" },
                ...config.options_status_stock,
              ]}
              defaultValue={values.statstock}
              selectOnBlur={false}
              onChange={handleSelectChangeStatStock}
            />

            <Form.Dropdown
              width="3"
              icon={null}
              fluid
              floating
              name="statitem"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Status Item : <b>{values.statitem}</b>
                </Button>
              }
              options={[
                { key: "all", text: "All", value: "All" },
                ...config.options_status_item,
              ]}
              defaultValue={values.statitem}
              selectOnBlur={false}
              onChange={handleSelectChange}
            />

            <Grid.Column floated="right">
              <div className="Bag-icon">
                <Image
                  className="bag"
                  circular
                  style={{ maxHeight: "60px" }}
                  spaced="left"
                  src={"../storage/imgs/bag.webp"}
                  onClick={() => setOpenbookinglist(true)}
                />
                <div className="NumberBook">{bookingCount}</div>
              </div>
            </Grid.Column>
          </Form.Group>
        </Form>

        {/* <Grid.Column floated="right">
          <div className="Bag-icon">
            <Image
              className="bag"
              circular
              style={{ maxHeight: "60px" }}
              spaced="left"
              src={"../storage/imgs/bag.webp"}
              onClick={() => setOpenbookinglist(true)}
            />
            <div className="NumberBook">5</div>
          </div>
        </Grid.Column> */}

        <br></br>

        {/* <TableList loading={loading} data={data} columns={column} /> */}
        <TableList
          loading={loading}
          data={data}
          columns={column}
          pageSize={per_page}
          page={current_page}
          pages={last_page}
          onPageChange={(page) => setCurrentPage(page)}
          onPageSizeChange={(value) => setPerPage(value)}
        />
      </Segment>

      <Modal
        size={errormsg ? "tiny" : "mini"}
        open={loadPopup}
        closeIcon={errormsg ? true : false}
        dimmer="blurring"
        closeOnDimmerClick={false}
        onClose={() => setLoadPopup(false)}
      >
        <Wrap>
          {errormsg ? (
            <Wrap>
              <Modal.Header>
                <Icon name="warning sign" /> Warning
              </Modal.Header>
              <Modal.Content>{errormsg}</Modal.Content>
            </Wrap>
          ) : (
            <Modal.Content>
              <div style={{ textAlign: "center" }}>
                <Icon loading name="circle notch" />
                <br />
                Booking proceeding
              </div>
            </Modal.Content>
          )}
        </Wrap>
      </Modal>
      {/* <ModalBasic
        size="large"
        open={openbookinglist}
        content=""
        close={() => setOpenbookinglist(false)}
      > */}

      <BookList
        // loading={loading}
        // data={data}
        // columnbookinglist={columnbookinglist}
        onBookingConfirm={onBookingConfirm}
        loading={loading}
        dataBooking={dataBooking}
        columnbookinglist={columnbookinglist}
        open={openbookinglist}
        userData={userData}
        onClose={() => setOpenbookinglist(false)}
        errormsg={errormsg}
      ></BookList>

      {/* </ModalBasic> */}

      <ModalBasic open={open} size="small" close={popUpClose}>
        <Header as="h5" style={{ textAlign: "center" }}>
          <div className="text1">
            Successful Booking <span>{msgConfirm1}</span>
          </div>
          <br />
          <div className="text1">
            Please pick up the book within <span>{msgConfirm2}</span>
          </div>
          <br />
          <br />
          <div className="text1">
            <span>
              If the pick up is overdue, the system will <br />
              cancel the reservation.
            </span>
          </div>
          <br />
        </Header>
        <center>
          <Button
            content="Confirm"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "5%",
            }}
            onClick={() => popUpClose()}
          />
        </center>
      </ModalBasic>

      {/* <ModalBasic
        size="large"
        open={showPopupBooks}
        content=""
        close={() => setShowPopupBooks(false)}
      >
        <FormBooks
          onSubmit={insertBook}
          onClose={() => setShowPopupBooks(false)}
          loading={loading}
          errormsg={errormsg}
        />
      </ModalBasic> */}
    </Segment>
  );
};
export default Books;
