import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import moment from "moment";
import * as config from "../../config";
import {
  Header,
  Image,
  Segment,
  Grid,
  Breadcrumb,
  Form,
  Loader,
  Dimmer,
  Tab,
  Table,
  Button,
  Icon,
  Modal,
  GridColumn,
} from "semantic-ui-react";

import Wrap from "../../components/wrap";
import TableList from "../../components/tablebooking";
import {
  getDetailHistory,
  getAllBooks,
  exportBook,
  postBook,
  getBookingList,
  deleteBooking,
  confirmBooking,
} from "../History/service";
import "./history.css";

const Historylist = (props) => {
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("kidzUserData"))
  ); //Todo
  const [bookChannel] = useState(
    props && props.location.state && props.location.state.channel
  );
  const [bookingID] = useState(
    props && props.location.state && props.location.state.booking_id
  );
  const [bookStatus] = useState(
    props && props.location.state && props.location.state.status
  );
  const [bookingDate] = useState(
    props && props.location.state && props.location.state.booking_date
  );
  const [pickupDueDate] = useState(
    props && props.location.state && props.location.state.pickup_due_date
  );
  const [borrowDate] = useState(
    props && props.location.state && props.location.state.borrow_date
  );
  const [loading, setLoading] = useState(true);
  const [datahistorylist, setDatahistorylist] = useState([]);
  const [columnhistorylist, setColumnhistorylist] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 50,
    },
    {
      Header: "Image",
      accessor: "id",
      sortable: false,
      Cell: (cellInfo) => (
        <Image
          size="mini"
          verticalAlign="middle"
          src={
            cellInfo.original.imageURL
              ? cellInfo.original.imageURL + "?time=" + new Date()
              : "../storage/imgs/book.png"
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "../storage/imgs/book.png";
          }}
        />
      ),
      style: { textAlign: "center" },
      width: 100,
    },
    {
      Header: "Category",
      accessor: "category",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Code",
      accessor: "book_code",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Brand",
      accessor: "brand",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Model",
      accessor: "model",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "ISBN",
      accessor: "isbn",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Name [EN]",
      accessor: "name_en",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Name [TH]",
      accessor: "name_th",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Age range",
      accessor: "age_range",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Author",
      accessor: "author",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Return Due Date",
      accessor: "return_due_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    // {
    //   Header: "Quantity",
    //   accessor: "quantity",
    //   style: { textAlign: "center" },
    //   sortable: false,
    // },
    {
      Header: "Unit",
      accessor: "unit",
      style: { textAlign: "center" },
      sortable: false,
    },
    // {
    //   Header: "Action",
    //   accessor: "id",
    //   style: { textAlign: "center" },
    //   sortable: false,
    //   Cell: (cellInfo) => (
    //     <Wrap>
    //       <Icon
    //         link
    //         bordered
    //         style={{
    //           color: "red",
    //           boxShadow: "0 0 0 0.1em " + "red" + " inset",
    //         }}
    //         name="trash alternate outline" //bin icon
    //         // onClick={() => onDeleteBooking(cellInfo.original.id)}
    //         // onClick={onBookingConfirm}
    //         // onClick={() =>
    //         //   props.history.push({
    //         //     pathname: "/booking/bookprofile",
    //         //     state: { id: cellInfo.original.id },
    //         //   })
    //         // }
    //       />
    //     </Wrap>
    //   ),
    // },
  ]);

  useEffect(() => {
    // if ((bookChannel){
    //   props.history.push("/history/historylist");
    // }

    setLoading(true);
    // console.log(bookChannel); //Test
    // console.log(bookStatus); //Test
    // console.log(bookingID); //Test
    // console.log(userData.id); //Test
    // if (bookChannel == "Booking") {
    getData();
    // }
  }, [bookChannel]);

  const getData = () => {
    //get history booking list data
    // if (bookChannel == "Booking") {
    let qString = "?";
    if (userData.id) qString = qString + "&student_id=" + userData.id; //Current user login
    if (bookingID) qString = qString + "&booking_id=" + bookingID;
    if (bookStatus) qString = qString + "&status=" + bookStatus;
    if (bookChannel) qString = qString + "&channel=" + bookChannel;
    if (borrowDate) qString = qString + "&borrow_date=" + borrowDate;

    getDetailHistory(qString).then((res) => {
      // console.log(res); //Test

      if (res && res.status === 200) {
        setColumnhistorylist(columnhistorylist);
        setDatahistorylist(res.data);
      }
      setLoading(false);
    });
    // }
  };

  return (
    <Wrap>
      <Segment
        className="contentSegment"
        textAlign="left"
        style={{ minHeight: "70vh", padding: "20px" }}
      >
        <Grid columns="2" doubling>
          <Grid.Column width="4">
            <Image
              style={{ maxHeight: "300px", maxwidth: "200px" }}
              centered
              spaced
              // size=""
              src={
                userData && userData.imageURL
                  ? userData.imageURL
                  : "../storage/imgs/dfusers.png"
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "../storage/imgs/dfusers.png";
              }}
            />
          </Grid.Column>
          <Grid.Column columns="2" width="12" style={{ minHeight: "30vh" }}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Header
                    as="h2"
                    style={{
                      color: config.colorTheme2,
                    }}
                  >
                    <Header.Content>
                      {userData.firstName}&nbsp;&nbsp;
                      {userData.lastName}
                      {/* {data.studentID} {data.title && data.title}{" "}
                            {data.firstName && data.firstName}&nbsp;&nbsp;
                            {data.lastName && data.lastName} */}
                      <Header.Subheader
                      // style={{ color: config.colorTheme2 }}
                      >
                        {/* {data.title_th && data.title_th}{" "}
                              {data.firstName_th && data.firstName_th}
                              &nbsp;&nbsp;{data.lastName_th && data.lastName_th} */}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="2">
                <Grid.Column columns="2">
                  <Grid columns="2">
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      Child's name (nick name)
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      {userData.nickname}
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      Gender
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      {userData.gender}
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      Date of birth
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      {userData.dateOfBirth}
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      Age
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      {moment().diff(
                        moment(userData.dateOfBirth, "DD-MM-YYYY"),
                        "years"
                      )}{" "}
                      Year old
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      E-mail
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      {userData.emailAddress}
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      Emergency contact no.
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      {userData.emergency_name}
                      <br />
                      {userData.emergency_phone}
                      <br />{" "}
                      {userData.emergency_relation &&
                        ` (${userData.emergency_relation})`}
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column columns="2">
                  <Grid columns="2">
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      Passport or ID No.
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      {userData.idnumber}
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      Student Type
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      {userData.studenttype}
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      Status
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      {userData.status}
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      Start Date
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      {userData.start_card}
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      End Date
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      {userData.end_card}
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid>

        <Wrap>
          <Grid columns="4" doubling>
            <Grid.Column columns="4" width="12" style={{ minHeight: "20vh" }}>
              <Grid.Row columns="4">
                <Grid.Column columns="4">
                  <Grid columns="4">
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      Booking ID
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      :&nbsp;&nbsp;&nbsp;{bookingID}
                    </Grid.Column>
                    <Grid.Column
                      style={{ paddingBottom: 0 }}
                      className="bold"
                    ></Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}></Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column columns="4">
                  <Grid columns="4">
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      Booking Date
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      :&nbsp;&nbsp;&nbsp;{bookingDate}
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      Status
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      :&nbsp;&nbsp;&nbsp;{bookStatus}
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      Pickup Due Date
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      :&nbsp;&nbsp;&nbsp;{pickupDueDate}
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      Borrow Date
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      :&nbsp;&nbsp;&nbsp;{borrowDate}
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </Wrap>

        <TableList
          data={datahistorylist}
          columns={columnhistorylist}
          loading={loading}
        />

        <button
          className="bl-back"
          onClick={() =>
            props.history.push({
              pathname: "/",
            })
          }
        >
          Back
        </button>
      </Segment>
    </Wrap>
  );
};

export default withRouter(Historylist);
