import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "./Booking.css";
import Wrap from "../../components/wrap";
import * as config from "../../config";
import {
  Header,
  Image,
  Segment,
  Grid,
  Breadcrumb,
  Form,
  Loader,
  Dimmer,
  Tab,
  Table,
  Button,
  Icon,
} from "semantic-ui-react";

// import BookItemsList from "./bookinglist"; // add for bookinglist
import TableList from "../../components/tablebooking";

const bookinglist = (props) => {
  const {
    open,
    onClose,
    userData,
    loading,
    dataBooking,
    columnbookinglist,
    onBookingConfirm,
  } = props;

  if (!open) return null;

  return (
    <div className="overlay">
      <div className="bl-container">
        <div className="scall-container">
          {/* <div className="close-btn">
          <button className="bl-cancel" onClick={onClose}>
            X
          </button>
        </div> */}
          {/* <div className="bl-User">
          <div className="bl-userdetail"></div>
        </div> */}
          <Wrap>
            <Segment
              className="contentSegment"
              textAlign="left"
              style={{ minHeight: "65vh", padding: "20px" }}
            >
              {/* {loadingPage ? (
              <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
            ) : ( */}
              <Wrap>
                <Grid columns="2" doubling>
                  <Grid.Column width="4">
                    <Image
                      style={{ maxHeight: "300px", maxwidth: "200px" }}
                      centered
                      spaced
                      // size=""
                      src={
                        userData && userData.imageURL
                          ? userData.imageURL
                          : "../storage/imgs/dfusers.png"
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "../storage/imgs/dfusers.png";
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column
                    columns="2"
                    width="12"
                    style={{ minHeight: "30vh" }}
                  >
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <Header
                            as="h2"
                            style={{
                              color: config.colorTheme2,
                            }}
                          >
                            <Header.Content>
                              {userData.firstName}&nbsp;&nbsp;
                              {userData.lastName}
                              {/* {data.studentID} {data.title && data.title}{" "}
                            {data.firstName && data.firstName}&nbsp;&nbsp;
                            {data.lastName && data.lastName} */}
                              <Header.Subheader
                              // style={{ color: config.colorTheme2 }}
                              >
                                {/* {data.title_th && data.title_th}{" "}
                              {data.firstName_th && data.firstName_th}
                              &nbsp;&nbsp;{data.lastName_th && data.lastName_th} */}
                              </Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns="2">
                        <Grid.Column columns="2">
                          <Grid columns="2">
                            <Grid.Column
                              style={{ paddingBottom: 0 }}
                              className="bold"
                            >
                              Child's name (nick name)
                            </Grid.Column>
                            <Grid.Column style={{ paddingBottom: 0 }}>
                              {userData.nickname}
                            </Grid.Column>
                            <Grid.Column
                              style={{ paddingBottom: 0 }}
                              className="bold"
                            >
                              Gender
                            </Grid.Column>
                            <Grid.Column style={{ paddingBottom: 0 }}>
                              {userData.gender}
                            </Grid.Column>
                            <Grid.Column
                              style={{ paddingBottom: 0 }}
                              className="bold"
                            >
                              Date of birth
                            </Grid.Column>
                            <Grid.Column style={{ paddingBottom: 0 }}>
                              {userData.dateOfBirth}
                            </Grid.Column>
                            <Grid.Column
                              style={{ paddingBottom: 0 }}
                              className="bold"
                            >
                              Age
                            </Grid.Column>
                            <Grid.Column style={{ paddingBottom: 0 }}>
                              {moment().diff(
                                moment(userData.dateOfBirth, "DD-MM-YYYY"),
                                "years"
                              )}{" "}
                              Year old
                            </Grid.Column>
                            <Grid.Column
                              style={{ paddingBottom: 0 }}
                              className="bold"
                            >
                              E-mail
                            </Grid.Column>
                            <Grid.Column style={{ paddingBottom: 0 }}>
                              {userData.emailAddress}
                            </Grid.Column>
                            <Grid.Column
                              style={{ paddingBottom: 0 }}
                              className="bold"
                            >
                              Emergency contact no.
                            </Grid.Column>
                            <Grid.Column style={{ paddingBottom: 0 }}>
                              {userData.emergency_name}
                              <br />
                              {userData.emergency_phone}
                              <br />{" "}
                              {userData.emergency_relation &&
                                ` (${userData.emergency_relation})`}
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                        <Grid.Column columns="2">
                          <Grid columns="2">
                            <Grid.Column
                              style={{ paddingBottom: 0 }}
                              className="bold"
                            >
                              Passport or ID No.
                            </Grid.Column>
                            <Grid.Column style={{ paddingBottom: 0 }}>
                              {userData.idnumber}
                            </Grid.Column>
                            <Grid.Column
                              style={{ paddingBottom: 0 }}
                              className="bold"
                            >
                              Student Type
                            </Grid.Column>
                            <Grid.Column style={{ paddingBottom: 0 }}>
                              {userData.studenttype}
                            </Grid.Column>
                            <Grid.Column
                              style={{ paddingBottom: 0 }}
                              className="bold"
                            >
                              Status
                            </Grid.Column>
                            <Grid.Column style={{ paddingBottom: 0 }}>
                              {userData.status}
                            </Grid.Column>
                            <Grid.Column
                              style={{ paddingBottom: 0 }}
                              className="bold"
                            >
                              Start Date
                            </Grid.Column>
                            <Grid.Column style={{ paddingBottom: 0 }}>
                              {userData.start_card}
                            </Grid.Column>
                            <Grid.Column
                              style={{ paddingBottom: 0 }}
                              className="bold"
                            >
                              End Date
                            </Grid.Column>
                            <Grid.Column style={{ paddingBottom: 0 }}>
                              {userData.end_card}
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                      {/*Todo <Grid.Row>
                        <Grid.Column
                          width="4"
                          style={{ paddingBottom: "20px" }}
                          className="bold"
                        >
                          Course
                        </Grid.Column> */}
                      {/* <Grid.Column width="12">
                          {data.regist_course &&
                            data.regist_course.map((item) => (
                              <p>
                                {item.course_name} : {item.class_name} :{" "}
                                {item.teacher}
                                <br />
                                {item.term_name}
                              </p>
                            ))}
                        </Grid.Column> */}
                      {/* </Grid.Row>  Todo */}
                    </Grid>
                  </Grid.Column>
                </Grid>
                <br />
                <br />
              </Wrap>
              {/* )} */}

              {/* Table of booklist */}

              {/* <Bookinglist /> */}

              <TableList
                data={dataBooking}
                columns={columnbookinglist}
                loading={loading}
              />
            </Segment>
          </Wrap>
          <div className="close-btn">
            <button className="bl-save " onClick={onBookingConfirm}>
              Save
            </button>
            <button className="bl-cancel" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default bookinglist;
