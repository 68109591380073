import React from "react";
import { Modal, Header, Button } from "semantic-ui-react";
import * as config from "../config";
import Wrap from "./wrap";


const ModalForm = props => {
  return (
    <Modal
      open={props.open}
      centered={props.centered || false}
      onClose={props.close}
      dimmer="blurring"
      size={props.size || "small"}
      closeIcon={props.closeIcon || false}
      closeOnDimmerClick={false}
    >
      <Modal.Content scrolling>
        <Wrap>
          <Header
            as='h2'
            content={props.content}
            style={
              props.style
                ? { ...props.style, padding: 0, marginBottom: '30px' }
                : { padding: 0, marginBottom: '30px' }
            }
          />

          {props.children}


        </Wrap>
      </Modal.Content>

    </Modal>
  );
};
export default ModalForm;
