import React, { useState,useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Checkbox,
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
} from 'semantic-ui-react';
import AppRoutes from "../routes";
import Menulist from "./menu";
import HeaderMenu from "./headmenu";
import ErrorBoundary from "../components/errorBoundary";

const MobiileLayout = (props) => {

  const [visible, setVisible] = useState(false);

  return (
    <Sidebar.Pushable as={Segment} style={{ height: '100vh',border: 0,borderRadius: 0}}>
        <Sidebar
          as={Menu}
          animation='overlay'
          inverted
          vertical
          onHide={() => {
            setVisible(false)
          }}
          visible={visible}
          style={{ background:'#ffffff'}}
        >
          <div style={{ paddingTop:'10vh'}}>
            <Menulist  userData={props.userData}/>
          </div>
        </Sidebar>
        <Sidebar.Pusher dimmed={visible} >
          <Menu secondary  fixed='top' fluid
            style={{
                background:'#ffffff',
                height:'5vh',
                boxShadow: '0px 0px 10px -5px #222'
            }} className='FIXHEADER'>
            <Menu.Item
              icon='bars'
              onClick={() => setVisible(!visible)}
            />
          <Image src='../storage/imgs/logo.png'
            onClick={() => props.history.push({
                        pathname: '/'
                      })
                    }
            style={{  padding: '4px',height: '100%',cursor :'pointer'}} />
            <HeaderMenu userData={props.userData}/>
          </Menu>
          <div style={{background: '#9e9e9e26',paddingTop:'10vh',height:'100vh',overflow: 'auto'}}>
            <ErrorBoundary>
              <AppRoutes/>
            </ErrorBoundary>
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
  );
}
export default withRouter(MobiileLayout);
