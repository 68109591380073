import React, { useState, useEffect } from "react";
import MobiileLayout from "./mobile";
import DesktopLayout from "./desktop";

const MainLayout = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 768; //apple ipad mini
  const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem("kidzUserData")));

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  return (<div> {width < breakpoint ? <MobiileLayout userData={userData} /> : <DesktopLayout userData={userData} />}</div>);
}
export default MainLayout;
