//localhost
// export const urlAPI = "http://localhost:8000/api/v1/web";
//UAT
// export const urlAPI =  "https://api-kidz-dot-kidz-village.as.r.appspot.com/api/v1/web";
//Production
export const urlAPI =
  "https://api-kidz-dot-kidz-village-ii.as.r.appspot.com/api/v1/web";

export const headerCallAPI = {
  headers: {
    Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};
export const headerFileCallAPI = {
  headers: {
    Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
    Accept: "application/json",
    // "Content-Type": "multipart/form-data"
  },
};

export const colorTheme = "#7AC142";
export const colorTheme2 = "#80561B";

// library
//Use
export const options_status_item = [
  { key: "Active", text: "Active", value: "Active" },
  { key: "Inactive", text: "Inactive", value: "Inactive" },
];

//Use
export const options_status_stock = [
  { key: "Available", text: "Available", value: "Available" },
  { key: "Reserved", text: "Reserved", value: "Reserved" },
  { key: "Unavailable", text: "Unavailable", value: "Unavailable" },
];
//Use
export const options_bookType = [
  { key: "Book", text: "Book", value: "Book" },
  { key: "Audio", text: "Audio", value: "Audio" },
];

//Todo
export const options_bookingstatus = [
  { key: "Booking", text: "Booking", value: "Booking" },
  { key: "Borrowing", text: "Borrowing", value: "Borrowing" },
  { key: "Complete", text: "Complete", value: "Complete" },
  { key: "Overdue", text: "Overdue", value: "Overdue" },
  { key: "Cancel", text: "Cancel", value: "Cancel" },
];
//Todo
export const options_bookCategory = [
  { key: "Gardening", text: "Gardening", value: "Gardening" },
  { key: "Fantasy", text: "Fantasy", value: "Fantasy" },
  { key: "Art", text: "Art", value: "Art" },
  { key: "Languages", text: "Languages", value: "Languages" },
  { key: "Animals", text: "Animals", value: "Animals" },
  {
    key: "Social-Emotional",
    text: "Social-Emotional",
    value: "Social-Emotional",
  },
  { key: "Physical & Body", text: "Physical & Body", value: "Physical & Body" },
  { key: "Transportation", text: "Transportation", value: "Transportation" },
  { key: "Science", text: "Science", value: "Science" },
  { key: "AudioBook", text: "AudioBook", value: "AudioBook" },
  { key: "Game", text: "Game", value: "Game" },
  { key: "Cooking/Food", text: "Cooking/Food", value: "Cooking/Food" },
  { key: "Sing along", text: "Sing along", value: "Sing along" },
  {
    key: "Fable/Fairy Tale",
    text: "Fable/Fairy Tale",
    value: "Fable/Fairy Tale",
  },
  { key: "Math", text: "Math", value: "Math" },
  { key: "Guided Reading", text: "Guided Reading", value: "Guided Reading" },
  { key: "ICT", text: "ICT", value: "ICT" },
  { key: "Colors", text: "Colors", value: "Colors" },
  { key: "Culture", text: "Culture", value: "Culture" },
  { key: "Occupation", text: "Occupation", value: "Occupation" },
  { key: "Rhymes", text: "Rhymes", value: "Rhymes" },
  { key: "Sensory Book", text: "Sensory Book", value: "Sensory Book" },
  { key: "Mythology", text: "Mythology", value: "Mythology" },
  { key: "Historical", text: "Historical", value: "Historical" },
  { key: "Series", text: "Series", value: "Series" },
  { key: "Construction", text: "Construction", value: "Construction" },
  { key: "Comic / Graphic", text: "Comic / Graphic", value: "Comic / Graphic" },
  { key: "Storytelling", text: "Storytelling", value: "Storytelling" },
  { key: "Tools", text: "Tools", value: "Tools" },
  { key: "Thai Language", text: "Thai Language", value: "Thai Language" },
];
