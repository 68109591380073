import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import ModalBasic from "../../components/modal";
import * as config from "../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
} from "semantic-ui-react";

const validate = (values) => {
  const errors = {};
  if (!values.isbn) errors.isbn = true;
  if (!values.status) errors.status = true;
  return errors;
};

let FormBooks = (props) => {
  const {
    loading,
    pristine,
    submitting,
    handleSubmit,
    onSubmit,
    initialValue,
    onClose,
    errormsg,
    start_card,
    end_card,
  } = props;
  const [open, setOpen] = useState(false);

  const showPopup = () => {
    setOpen(true);
  };
  const popUpClose = () => {
    setOpen(false);
    window.location.reload();
    window.close();
  };

  return (
    <Segment basic textAlign="left">
      <ModalBasic open={open} size="big" close={popUpClose}>
        <Header as="h2" style={{ textAlign: "center" }}>
          ทำการบันทึกข้อมูลแล้ว
        </Header>
        <center>
          <Button
            content="OK"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "5%",
            }}
            onClick={() => popUpClose()}
          />
        </center>
      </ModalBasic>
    </Segment>
  );
};

FormBooks = reduxForm({
  // a unique name for the form
  form: "FormBooks",
  validate,
  enableReinitialize: true,
})(FormBooks);

const selector = formValueSelector("class");
FormBooks = connect((state) => {
  const start_card = selector(state, "start_card");
  const end_card = selector(state, "end_card");
  return {
    start_card: start_card,
    end_card: end_card,
  };
})(FormBooks);

export default FormBooks;
