import React from "react";
import { Button } from "semantic-ui-react";
import PropTypes from "prop-types";

const SecondButton = props => {
  const { size, onClick, content, fluid, loading, disable ,color, floated} = props;
  return (
    <Button
      style={{ backgroundColor: color, color: "#ffffff",minWidth: '30%' }}
      basic
      size={size}
      fluid={fluid}
      loading={loading}
      disabled={disable}
      onClick={onClick}
      content={content}
      floated={floated}
    />
  );
};

SecondButton.propTypes = {
  content: PropTypes.string.isRequired
};

export default SecondButton;
