import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

class TableData extends Component {
  render() {
    return (
      <ReactTable
        style={this.props.styleTB}
        defaultPageSize={this.props.defaultPageSize || 3}
        pageSize={this.props.pageSize}
        // defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
        // className="-striped -highlight"
        className="-highlight"
        loading={this.props.loading}
        data={this.props.data}
        columns={this.props.columns}
        defaultSorted={this.props.defaultSorted}
      />
    );
  }
}
export default TableData;
