import React, { useState, useEffect } from "react";
import { withRouter, NavLink, Link } from "react-router-dom";
import { Menu, Image, Icon, Accordion, List } from "semantic-ui-react";

const Menulist = (props) => {
  const [userData, setUserData] = useState(props.userData);
  // const [role, setRole] = useState(
  //   JSON.parse(sessionStorage.getItem("kidz_roles"))
  // );
  // const [allPermistion, setAllPermistion] = useState(
  //   JSON.parse(sessionStorage.getItem("kidz_permission"))
  // );
  const pathname =
    props.history && props.history.location
      ? props.history.location.pathname
      : window.location.pathname;
  const [openAccordion, setOpenAccordion] = useState(false);

  useEffect(() => {
    if (pathname.indexOf("management") > 0) setOpenAccordion(true);
  }, []);

  // const checkPermissionGroup = (group) => {
  //   let userpermissionGroup = allPermistion.find(
  //     (item) => item.group === group
  //   );
  //   if (userpermissionGroup) return true;
  //   else return false;
  // };

  // const checkPermissionMenu = (group, permission) => {
  //   let userpermissionGroup = allPermistion.find(
  //     (item) => item.group === group
  //   );
  //   let allowed = permission.find((item) => {
  //     if (
  //       userpermissionGroup &&
  //       userpermissionGroup.permission.indexOf(item) !== -1
  //     )
  //       return item;
  //   });
  //   if (allowed) return true;
  //   else return false;
  // };

  return (
    <div>
      <Image
        circular
        // style={{ maxHeight: "100px" }}
        style={{ maxHeight: "100px", maxwidth: "100px" }}
        centered
        spaced
        size="tiny"
        src={
          userData && userData.imageURL
            ? userData.imageURL
            : "../storage/imgs/dfusers.png"
        }
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "../storage/imgs/dfusers.png";
        }}
      />
      <h4 style={{ marginTop: "10px", marginBottom: "10px" }} className="bold">
        {userData.firstName} {userData.lastName}{" "}
      </h4>
      {/* <h5 style={{ marginTop: "0px", marginBottom: "0px" }} className="bold">
        {userData.employee_type}
      </h5>
      <h5 style={{ marginTop: "0px", marginBottom: "0px" }} className="bold">
        {role && role.toString()}
      </h5> */}

      <br />
      <br />
      <h5
        style={{
          marginTop: "0px",
          marginBottom: "0px",
          marginLeft: "10px",
          textAlign: "start",
        }}
        className="bold"
      >
        Library
      </h5>
      <Menu
        secondary
        fluid
        vertical
        style={{ textAlign: "left", fontSize: "99%" }}
      >
        <Menu.Item
          className="subMenu"
          as={NavLink}
          to="/booking"
          activeClassName="active"
          exact
          active={
            pathname.indexOf("booking") > 0 ||
            pathname.indexOf("booking/bookprofile") > 0
          }
        >
          Booking
        </Menu.Item>
        <Menu.Item
          className="subMenu"
          as={NavLink}
          // to="/parent"
          to="/"
          activeClassName="active"
          exact
          // active={
          //   // pathname.indexOf("parent") > 0 ||
          //   // pathname.indexOf("parent/profile") > 0
          //   pathname.indexOf("history") > 0 ||
          //   pathname.indexOf("history/history") > 0
          // }
          active={pathname.indexOf("history") > 0}
        >
          History
        </Menu.Item>
        {/* <Menu.Item
          className="subMenu"
          as={NavLink}
          to="/dashboard"
          activeClassName="active"
          exact
          active={
            pathname.indexOf("/") > 0 || pathname.indexOf("dashboard") > 0
          }
        >
          Dashboard
        </Menu.Item> */}

        {/* {checkPermissionMenu("Attendance", ["class-attendance"]) && (
          <Menu.Item
            className="subMenu"
            as={NavLink}
            to="/classAttendance"
            activeClassName="active"
            exact
            active={pathname.indexOf("classAttendance") > 0}
          >
            Class Attendance
          </Menu.Item>
        )} */}

        {/*
                <Menu.Item
                 className="subMenu"
                 as={NavLink}
                 to="/classattendance"
                 activeClassName="active"
                 exact
               >
                 Class Attendance
               </Menu.Item>*/}
        {/* {checkPermissionMenu("Personnel", [
          "manage-student",
          "view-student",
        ]) && (
          <Menu.Item
            className="subMenu"
            as={NavLink}
            to="/booking"
            activeClassName="active"
            exact
            active={
              pathname.indexOf("booking") > 0 ||
              pathname.indexOf("booking/bookprofile") > 0
            }
          >
            Booking
          </Menu.Item>
        )} */}
        {/* 
        {checkPermissionMenu("Personnel", ["import-student"]) && (
          <Menu.Item
            className="subMenu"
            as={NavLink}
            to="/import"
            activeClassName="active"
            exact
            active={pathname.indexOf("import") > 0}
          >
            ImportStudent
          </Menu.Item>
        )} */}

        {/* {checkPermissionMenu("Personnel", ["register-student"]) && (
          <Menu.Item
            className="subMenu"
            as={NavLink}
            to="/register"
            activeClassName="active"
            exact
            active={pathname.indexOf("import") > 0}
          >
            Register
          </Menu.Item>
        )} */}
        {/* {checkPermissionMenu("Personnel", ["manage-parent", "view-parent"]) && (
          <Menu.Item
            className="subMenu"
            as={NavLink}
            // to="/parent"
            to="/"
            activeClassName="active"
            exact
            // active={
            //   // pathname.indexOf("parent") > 0 ||
            //   // pathname.indexOf("parent/profile") > 0
            //   pathname.indexOf("history") > 0 ||
            //   pathname.indexOf("history/history") > 0
            // }
            active={pathname.indexOf("history") > 0}
          >
            History
          </Menu.Item>
        )} */}
        {/* {checkPermissionGroup("Report") && (
          <Menu.Item
            className="subMenu"
            as={NavLink}
            to="/report"
            activeClassName="active"
            exact
            active={pathname.indexOf("report") > 0}
          >
            Report
          </Menu.Item>
        )}

        <Accordion>
          <Accordion.Title
            active={openAccordion}
            index={0}
            onClick={() => setOpenAccordion(!openAccordion)}
          >
            <Menu.Item
              className="subMenu"
              style={{ margin: 0 }}
              as={NavLink}
              to="/management"
              activeClassName="active"
              exact
              // active={
              //   pathname.indexOf("management") > 0
              // }
            >
              Management
            </Menu.Item>
          </Accordion.Title>
          <Accordion.Content active={openAccordion} style={{ padding: 0 }}>
            <List>
              {checkPermissionMenu("Academic", [
                "manage-course",
                "manage-registration",
                "manage-class",
              ]) && (
                <List.Item
                  className="accordionMenu"
                  as={Link}
                  to="/management/course"
                  active={
                    pathname.indexOf("management/course") > 0 ||
                    pathname.indexOf("course/profile") > 0
                  }
                >
                  Course
                </List.Item>
              )}
              {checkPermissionMenu("Academic", ["manage-course"]) && (
                <List.Item
                  className="accordionMenu"
                  as={Link}
                  to="/management/term"
                  active={pathname.indexOf("management/term") > 0}
                >
                  Term
                </List.Item>
              )}
              {checkPermissionMenu("Personnel", [
                "manage-teacher",
                "view-teacher",
              ]) && (
                <List.Item
                  className="accordionMenu"
                  as={Link}
                  to="/management/teacher"
                  active={
                    pathname.indexOf("management/teacher") > 0 ||
                    pathname.indexOf("teacher/profile") > 0
                  }
                >
                  Teacher/TA
                </List.Item>
              )}
              {checkPermissionMenu("Personnel", [
                "manage-staff",
                "view-staff",
              ]) && (
                <List.Item
                  className="accordionMenu"
                  as={Link}
                  to="/management/staff"
                  active={
                    pathname.indexOf("management/staff") > 0 ||
                    pathname.indexOf("staff/profile") > 0
                  }
                >
                  Staff/Nanny
                </List.Item>
              )}
              {checkPermissionMenu("Management", ["manage-user"]) && (
                <List.Item
                  className="accordionMenu"
                  as={Link}
                  to="/management/user"
                  active={pathname.indexOf("management/user") > 0}
                >
                  User
                </List.Item>
              )}
              {checkPermissionMenu("Management", ["manage-role"]) && (
                <List.Item
                  className="accordionMenu"
                  as={Link}
                  to="/management/role"
                  active={pathname.indexOf("management/role") > 0}
                >
                  Role & Permission
                </List.Item>
              )}
            </List>
          </Accordion.Content>
        </Accordion> */}
      </Menu>
    </div>
  );
};
export default Menulist;
