import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as config from "../../config";
import { Button, Header, Icon, Image, Segment, Form } from "semantic-ui-react";
import TableList from "../../components/tablePerPage";
import Wrap from "../../components/wrap";
import { DateInput } from "semantic-ui-calendar-react";
import { getBookHistory } from "./service";
import "./history.css";

const HistoryList = (props) => {
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("kidzUserData"))
  );

  // const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [date, setDate] = useState();
  const [loading, setLoading] = useState(false);
  const [showPopupProfile, setShowPopupProfile] = useState(false);

  const [values, setValues] = useState({
    // class: "",
    // course: "",
    status: "All", //Todo
  });

  const [search, setSearch] = useState({ name: null });
  const [per_page, setPerPage] = useState(10);
  const [current_page, setCurrentPage] = useState(0);
  // const [sort_page, setSortPage] = useState(null);
  const [last_page, setLastPage] = useState(1);
  const [data, setData] = useState([]);
  const [column, setColumn] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 40,
    },
    {
      Header: "Channel",
      accessor: "channel",
      style: { textAlign: "center" },
      sortable: false,
      width: 65,
    },
    {
      Header: "Booking ID",
      accessor: "booking_id",
      style: { textAlign: "center" },
      sortable: false,
      width: 75,
    },
    {
      Header: "Borrower",
      accessor: "fullname",
      sortable: false,
      width: 150,
      // Cell: (cellInfo) => (
      //   <Wrap>
      //     <span
      //       className="bold"
      //       style={{ cursor: "pointer" }}
      //       onClick={() =>
      //         props.history.push({
      //           pathname: "/history/bookinglist",
      //           // search: '?query=abc',
      //           state: { id: cellInfo.original.id, page: "history" },
      //         })
      //       }
      //     >
      //       {cellInfo.original.title} {cellInfo.original.firstName}{" "}
      //       {cellInfo.original.lastName}
      //     </span>
      //   </Wrap>
      // ),
    },
    {
      Header: "Booking Date",
      accessor: "booking_date",
      sortable: false,
    },
    {
      Header: "Pickup Due Date",
      accessor: "pickup_due_date",
      sortable: false,
    },
    {
      Header: "Borrowed Date",
      accessor: "borrow_date",
      sortable: false,
    },
    {
      Header: "Return Due Date",
      accessor: "return_due_date",
      sortable: false,
    },
    {
      Header: "Return Date",
      accessor: "return_date",
      sortable: false,
    },
    {
      Header: "Status",
      accessor: "status",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original.status === "Complete" ||
        cellInfo.original.status === null ? (
          <div className="complete">{cellInfo.original.status} </div>
        ) : (
          <div className="other">{cellInfo.original.status}</div>
        ),
    },
    {
      Header: "Action",
      accessor: "booking_id",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => (
        <Wrap>
          <Icon
            link
            bordered
            // color="green"
            style={{
              color: config.colorTheme,
              boxShadow: "0 0 0 0.1em " + config.colorTheme + " inset",
            }}
            name="user"
            onClick={() =>
              props.history.push({
                pathname: "/history/historylist",
                // search: '?query=abc',
                state: { id: cellInfo.original.id, page: "history" },
                state: {
                  channel: cellInfo.original.channel,
                  status: cellInfo.original.status,
                  booking_id: cellInfo.original.booking_id,
                  booking_date: cellInfo.original.booking_date,
                  pickup_due_date: cellInfo.original.pickup_due_date,
                  borrow_date: cellInfo.original.borrow_date,
                },
              })
            }
          />
        </Wrap>
      ),
      width: 60,
    },
  ]);

  // const timeoutRef = useRef(null);

  useEffect(() => {
    if (!loading) {
      getData();
    }
  }, [per_page, current_page, values, date]);

  const getData = () => {
    setLoading(true);
    let page = current_page + 1;
    let qString = "?_perpage=" + per_page + "&page=" + page;

    // let qString = "?";
    if (userData.id) qString = qString + "&student_id=" + userData.id; //Current user login
    if (values.status && values.status != "All")
      qString = qString + "&status=" + values.status;
    // if (search) {  //Cancel search borrower
    //   if (search.name) qString = qString + "&fullname=" + search.name;
    // }
    if (date) {
      qString = qString + "&borrowdate=" + date;
    }

    getBookHistory(qString).then((res) => {
      // console.log(res); //Test
      if (res && res.status === 200) {
        setColumn(column);
        setData(res.data.data);
        setPerPage(per_page);
        setCurrentPage(res.data.current_page - 1);
        setLastPage(res.data.last_page);
      }

      setLoading(false);
    });
  };

  // Borrower filter change
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setSearch({ ...values, [name]: value });
  // };
  // Borrow status filter changed
  const handleSelectChange = (e, data) => {
    const { name, value } = data;
    setValues({ ...values, [name]: value });
  };
  // Date filter change
  const handleChange = (event, { value }) => {
    //2022.08.05
    setDate(value);
  };

  //Todo
  const onEditProfile = () => {
    setData({
      ...data,
      bloodType: data["health"]["bloodType"],
      healthConditions: data["health"]["healthConditions"],
      race: data["health"]["race"],
      allergy: data["health"]["allergy"],
      reaction: data["health"]["reaction"],
      treatment: data["health"]["treatment"],
      hospital_name: data["health"]["hospital_name"],
      doctor_name: data["health"]["doctor_name"],
      doctor_phone: data["health"]["doctor_phone"],
    });
    setShowPopupProfile(true);
  };

  return (
    <Segment basic textAlign="left">
      <Header as="h2">Borrow-Return (Book)</Header>
      <Segment className="contentSegment" textAlign="left">
        <Form>
          <Form.Group>
            <Form.Field width={4}>
              <DateInput
                name="date"
                placeholder="Date"
                value={date}
                iconPosition="left"
                dateFormat="YYYY-MM-DD"
                onChange={handleChange}
              />
            </Form.Field>
            {/* Box search borrower */}
            {/* <Form.Input
              width="4"
              icon="search"
              size="small"
              placeholder="Search borrower"
              name="name"
              onChange={handleInputChange}
            /> */}
            {/* Box filter borrow status */}
            <Form.Dropdown
              width="4"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="status"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Status : <b>{values.status}</b>
                </Button>
              }
              /*options={[
                { key: "af", value: "Active", text: "Active" },
                { key: "ax", value: "Inactive", text: "Inactive" },
              ]} */
              // options={[
              //   { key: "af", value: "All", text: "All" },
              //   { key: "ax", value: "Booking", text: "Booking" },
              //   { key: "ax", value: "Borrowing(Overdue)", text: "Borrowing(Overdue)"},
              //   { key: "ay", value: "Borrowing", text: "Borrowing" },
              //   { key: "az", value: "Complete", text: "Complete" },
              //   { key: "az", value: "Cancel", text: "Cancel" },
              // ]}
              options={[
                { key: "all", text: "All", value: "All" },
                ...config.options_bookingstatus,
              ]}
              defaultValue={values.status}
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
          </Form.Group>
        </Form>

        <TableList
          loading={loading}
          data={data}
          columns={column}
          pageSize={per_page}
          page={current_page}
          pages={last_page}
          onPageChange={(page) => setCurrentPage(page)}
          onPageSizeChange={(value) => setPerPage(value)}
          // onSortedChange={(value) => setSortPage(value)}
        />
      </Segment>
    </Segment>
  );
};
export default withRouter(HistoryList);
