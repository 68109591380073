import axios from "axios";
import * as config from "../../config";

// book
export function getBook(qString) {
  return axios
    .get(config.urlAPI + "/report/book" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportBook(qString) {
  return axios
    .get(config.urlAPI + "/export/book" + qString, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postBook(data) {
  return axios
    .post(config.urlAPI + "/books", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
//Use
export function getBookProfile(booklist_id) {
  return axios
    .get(config.urlAPI + "/bookprofile/" + booklist_id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateBookProfile(data) {
  return axios
    .post(config.urlAPI + "/updatebookprofile", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateImageBook(data, id) {
  return axios
    .post(config.urlAPI + "/book/upload/" + id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateStatus(data) {
  return axios
    .post(config.urlAPI + "/updatestatus", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

//Todo
//All books
export function getAllBooks(qString) {
  return (
    axios
      // .get(config.urlAPI + "/report/allbooks" + qString, config.headerCallAPI)
      .get(config.urlAPI + "/allbooksperpage" + qString, config.headerCallAPI)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      })
  );
}

//Todo
//Insert to BookingList table
export function postBookingList(data) {
  return axios
    .post(config.urlAPI + "/booking/bookprofile", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
//Todo
//Get Booking list of student
export function getBookingList(student_id) {
  return axios
    .get(config.urlAPI + "/booking" + student_id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
//Todo
//Delete from Booking list
export function deleteBooking(book_id) {
  return axios
    .post(config.urlAPI + "/booking", book_id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
//Todo
//Insert to BookingList table
export function confirmBooking(data) {
  return axios
    .put(config.urlAPI + "/booking", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
