import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Checkbox,
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
} from 'semantic-ui-react';
import AppRoutes from "../routes";
import Menulist from "./menu";
import HeaderMenu from "./headmenu";
import ErrorBoundary from "../components/errorBoundary";

const DesktopLayout = (props) => {

  return (
    <Grid>
      <Grid.Row style={{padding: 0}}>

        <Grid.Column>
          <Menu secondary  fixed='top' fluid
            style={{
                background:'#ffffff',
                height:'20vh',
                boxShadow: '0px 0px 10px -5px #222'
            }} className='FIXHEADER'>
            <Image src='../storage/imgs/logo.png'
              onClick={() => props.history.push({
                          pathname: '/'
                        })
                      }
              style={{height: '100%',
                padding: '4px',
                paddingLeft: '20px',
                marginLeft :'2.5em',
                cursor :'pointer'
              }}
            />
            <HeaderMenu userData={props.userData}/>
          </Menu>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row  style={{padding: 0}}>
        <Grid.Column width='3' style={{  marginTop:'5vh',paddingTop:'4%',paddingRight: '0px' }}>
          <Menulist  userData={props.userData}/>
        </Grid.Column>
        <Grid.Column width='13'  style={{background: '#9e9e9e26' ,marginTop:'5vh',paddingTop:'4%' ,minHeight: '100vh'}}>
          <ErrorBoundary>
            <AppRoutes/>
          </ErrorBoundary>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
export default withRouter(DesktopLayout);
