import React from "react";
import "./bookconfirm.css";
import Alert from "react-popup-alert";
import Successlogo from "./success2.png";

const bookconfirm = ({ open, onClose }) => {
  if (!open) return null;
  return (
    <div className="overlay">
      <div className="container">
        <h1>booking Successfully</h1>
        <img src={Successlogo} alt="logo" />
        <div className="btn">
          <button className="cancel" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default bookconfirm;
